var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu mb-3", attrs: { id: "menu-" + _vm.data.block } },
    [
      _c("div", { staticClass: "h-14 menu__header d-flex align-center px-4" }, [
        _c(
          "div",
          { staticClass: "menu__header--logo" },
          [
            _c("v-img", {
              attrs: {
                width: "30",
                src: require(`@/assets/icons/menu/${_vm.data.type}.png`),
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "menu__header--title font-weight-bold icon--text ml-2",
          },
          [_vm._v(" " + _vm._s(_vm.data.data.navbar[0].game_type) + " ")]
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "mySwiper px-4 py-0",
              attrs: { options: _vm.swiperOption },
            },
            _vm._l(_vm.gameItems, function (game) {
              return _c(
                "swiper-slide",
                { key: game._id, staticClass: "game__item" },
                [
                  _c("div", { staticClass: "game__item--card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "rounded overflow-hidden",
                        class: game.status === "0" ? "maintenanceBox" : "",
                        style: _vm.gradientBg,
                      },
                      [
                        game.status == "0" ? _c("maintenance") : _vm._e(),
                        _c("v-img", {
                          staticClass: "rounded cursor-pointer",
                          attrs: {
                            src: _vm.baseImgUrl + game.image_h5,
                            height: "152",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.lauchGame(game.game_code)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "subtitle-2 icon--text" }, [
                      _vm._v(" " + _vm._s(game.title) + " "),
                    ]),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }