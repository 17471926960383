<template>
  <!-- 棋牌選單 type1 -->
  <div
    :id="'menu-' + data.block"
    class="menu mb-3"
  >
    <!-- header -->
    <div class="h-14 menu__header d-flex align-center px-4">
      <div class="menu__header--logo">
        <v-img
          width="30"
          :src="require(`@/assets/icons/menu/${ data.type }.png`)"
        />
      </div>
      <div class="menu__header--title font-weight-bold icon--text ml-2">
        {{ data.data.navbar[0].game_type }}
      </div>
    </div>

    <!-- items -->
    <div>
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper px-4 py-0"
      >
        <swiper-slide
          v-for="game in gameItems"
          :key="game._id"
          class="game__item"
        >
          <div
            class="game__item--card "
          >
            <div
              class="rounded overflow-hidden"
              :style="gradientBg"
              :class="game.status==='0'?'maintenanceBox':''"
            >
              <!-- 維修中 父層要加 :class="game.status==='0'?'maintenanceBox':''"-->
              <maintenance v-if="game.status=='0'" />
              <v-img
                :src="baseImgUrl + game.image_h5"
                height="152"
                class="rounded  cursor-pointer"
                @click="lauchGame(game.game_code)"
              />
            </div>
            <span class="subtitle-2 icon--text">
              {{ game.title }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import hexToRgba from 'hex-to-rgba'
import maintenance from '@/components/maintenance'

export default {
  components: { maintenance },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    swiperOption: {
      slidesPerView: 2.1,
      spaceBetween: 8,
      centeredSlidesBounds: true,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'gradientBg', 'theme']),

    gameItems() {
      return this.data.data.navbar[0].items
    },

    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },

    vendorBg() {
      return `background:linear-gradient(${hexToRgba(this.primaryColor)}, ${hexToRgba(this.primaryColor, '.3')});`
    },
  },

  methods: {
    /**
     * 啟動遊戲
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    lauchGame(gameCode) {
      this.$router.push({
        name: 'launchGame',
        query: {
          t: gameCode,
          redirectUrl: this.$route.path,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.h-14 {
	height: 48px;
}

.menu__header--title {
	font-size: 18px;
}
</style>
